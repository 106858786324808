<template>
  <div>
    <div class="box">
      <img src="../../assets/images/cp240201/cp240201-04.jpg" alt="" />
      <div class="erweima-box">
        <!-- <img class="btn1" src="../../assets/images/1stanniv/btm1.png" alt="">  -->
        <a href="https://apps.apple.com/app/id1597706553">
          <img
          style="width: 100%;height:100%"
            class="btn2"
            src="../../assets/images/1stanniv/btm2.png"
            alt=""
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.tripellet.app&hl=en-TW"
        >
          <img
            style="width: 100%;height:100%"
            class="btn2"
            src="../../assets/images/1stanniv/btm3.png"
            alt=""
          />
        </a>
      </div>
      <!-- <div class="snsIconBox">
          <a href="https://www.facebook.com/travelcontents/">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon1.png" alt="">
            </a>
            <a href="https://www.instagram.com/travelcontents.tw/">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon2.png" alt="">
            </a>
            <a href="https://m.youtube.com/channel/UCippIPsWWMvz2HZe_Dl0g8Q/featured">
              <img class="btn2" src="../../assets/images/1stanniv/snsIcon3.png" alt="">
            </a>
        </div> -->
      <div class="video-box">
        <iframe
          src="https://www.youtube.com/embed/Th3MA4OoBcI"
          frameborder="0"
        ></iframe>
      </div>
      <!-- 版权文字 -->
      <!-- <div class="copyright-box">
        © Nintendo & © Universal Studios. All rights reserved.
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
//   data () {
//     return {
//       bgImg:require('../../assets/images/sushirosgcp/sushirosgcp.jpg')
//     }
//   },
//   mounted () {
//     console.log(this._isMobile());
//     if(this._isMobile()){
//       this.bgImg = ''
//     }else{

//     }
//   },
// methods:{
//   _isMobile() {
//       let flag = navigator.userAgent.match(
//         /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//       );
//       return flag;
//     },
// }
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  img {
    width: 100%;
  }

  .erweima-box {
    width: 59%;
    // height: auto;
    // width: 414px;
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.7%;
    .btn1 {
      width: 18%;
      height: 18%;
    }
    a {
      width: 48%;
      height: 100%;
    }
  }
  .snsIconBox {
    width: 13%;
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    a {
      width: 24%;
      height: 24%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .video-box {
    position: absolute;
    top: 39.8%;
    left: 50%;
    transform: translateX(-50%);
    width: 86.1%;
    height: 8%;
    background-color: #000;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .copyright-box{
    font-size: 1.4vw;
    position: absolute;
    bottom: 9.1%;
    left: 2%;
  }
}

@media (min-width: 850px) {
    .copyright-box{
    font-size: 12px !important;
  }
}
</style>
